<template>
    <div
        class="uk-container uk-padding-small uk-container-expand card-scrollable"
    >
        <div class="uk-flex uk-flex-row uk-margin-medium-bottom">
            <button
                type="button"
                class="uk-button uk-button-default uk-margin-small-right"
                @click="goBack"
            >
                <span
                    class="uk-margin-small-right"
                    uk-icon="icon: arrow-left"
                ></span>
                <span>Back</span>
            </button>
            <p class="uk-margin-remove uk-text-large">
                {{
                    `Overtime data for ${dateCompleteStartDate} to ${dateCompleteEndDate}`
                }}
            </p>
        </div>

        <div class="uk-flex uk-flex-column">
            <p class="uk-margin-small-bottom">Choose date</p>
            <div class="uk-flex uk-flex-row">
                <div
                    class="uk-inline uk-margin-small-bottom uk-width-1-3"
                    @click="showModal"
                >
                    <a
                        href="#"
                        class="uk-form-icon uk-form-icon-flip"
                        uk-icon="icon: calendar"
                    ></a>
                    <input
                        class="uk-input"
                        type="text"
                        :value="filterDateText()"
                        disabled
                    />
                </div>
                <div class="uk-margin-small-left uk-width-1-6">
                    <select
                        id="sortFilter"
                        class="uk-select"
                        :class="[meta.status == 0 ? 'bg-yellow' : '']"
                        v-model="meta.status"
                        @change="(e) => changeMeta(e, 'status')"
                    >
                        <option :value="null" selected>All Status</option>
                        <option value="1">All Clear</option>
                        <option value="0">Waiting</option>
                    </select>
                </div>
                <div class="uk-margin-small-left uk-width-1-6">
                    <select
                        id="sortFilter"
                        class="uk-select"
                        v-model="meta.method"
                        @change="(e) => changeMeta(e, 'method')"
                    >
                        <option value="3">All Method</option>
                        <option value="1">Bottom Up</option>
                        <option value="2">Top Down</option>
                    </select>
                </div>
            </div>
            <div class="uk-flex uk-flex-row">
                <div class="uk-inline uk-width-1-3">
                    <span
                        class="uk-form-icon uk-form-icon-flip"
                        uk-icon="icon: search"
                    ></span>
                    <input
                        type="text"
                        class="uk-input"
                        placeholder="Search worker by name"
                        @keyup.enter="(e) => changeMeta(e, 'worker_name')"
                    />
                </div>
                <select
                    id="officeFilter"
                    class="uk-select uk-width-1-6 uk-margin-small-right uk-margin-small-left"
                    v-model="meta.company_office_id"
                    @change="(e) => changeMeta(e, 'company_office_id')"
                >
                    <option :value="null" selected>All Office</option>
                    <option
                        v-for="(office, index) in officeOptions"
                        :key="index"
                        :value="office._id"
                    >
                        {{ office.name }}
                    </option>
                </select>
                <select
                    id="roleFilter"
                    class="uk-select uk-width-auto uk-margin-small-right"
                    v-model="meta.role_id"
                    @change="(e) => changeMeta(e, 'role_id')"
                >
                    <option :value="null" selected>All Role</option>
                    <option
                        v-for="(role, index) in roleOptions"
                        :key="index"
                        :value="role._id"
                    >
                        {{ role.name }}
                    </option>
                </select>
                <select
                    id="sortFilter"
                    class="uk-select uk-width-auto"
                    v-model="meta.sorting"
                    @change="(e) => changeMeta(e, 'sorting')"
                >
                    <option :value="null" selected>Latest</option>
                    <option value="1">Name A-Z</option>
                    <option value="0">Name Z-A</option>
                </select>
                <div
                    v-if="meta.status == 0 && $can('EDIT', 'worker-overtime-report')"
                    class="uk-width-expand uk-flex uk-flex-row uk-flex-right"
                >
                    <button
                        class="action-button uk-button uk-button-primary uk-border-rounded uk-margin-small-left"
                        :disabled="selectedMitra < 1"
                        @click="showConfirmation({verify:true})"
                    >
                        Verify
                    </button>
                    <button
                        class="action-button uk-button uk-button-danger uk-border-rounded uk-margin-small-left"
                        :disabled="selectedMitra < 1"
                        @click="showConfirmation({verify:false})"
                    >
                        Reject
                    </button>
                    <button
                        class="action-button uk-button uk-button-default uk-border-rounded uk-margin-small-left"
                        @click="unselectAllMitra"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>

        <div
            v-if="isLoading"
            class="uk-flex uk-flex-center uk-flex-middle"
            style="min-height: 100%"
        >
            <div uk-spinner="ratio: 2"></div>
        </div>

        <div v-else class="uk-card uk-card-default uk-margin">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-medium uk-table-striped">
                    <thead>
                        <th
                            v-if="isShowMultipleSelect && lists.docs.length > 0 && $can('EDIT', 'worker-overtime-report')"
                            class="uk-text-center"
                        >
                            <input
                                type="checkbox"
                                class="uk-checkbox uk-border-rounded"
                                :checked="selectedMitra.length > 0"
                                @change="selectAllMitra"
                            />
                        </th>
                        <th class="uk-text-left">Waiting Verification</th>
                        <th class="uk-text-left">Full Name</th>
                        <th class="uk-text-left">Office</th>
                        <th class="uk-text-left">Role</th>
                        <th class="uk-text-center">Total OT <br>(hrs)</th>
                        <th class="uk-text-center">OT accepted <br>(hrs)</th>
                        <th class="uk-text-center">OT assigned <br>(hrs)</th>
                        <th class="uk-text-center">Avg OT <br>(hrs)</th>
                    </thead>
                    <LoadingTable v-if="isLoading" :colspan="8" />
                    <tbody v-else-if="lists.docs.length > 0">
                        <tr v-for="(list, i) in lists.docs" :key="i">
                            <td
                                v-if="
                                    isShowMultipleSelect &&
                                    lists.docs.length > 0
                                    && $can('EDIT', 'worker-overtime-report')
                                "
                                class="uk-text-center"
                            >
                                <input
                                    type="checkbox"
                                    class="uk-checkbox uk-border-rounded"
                                    :checked="
                                        findSelectedMitra({ _id: list._id })
                                    "
                                    :disabled="list.overtime_waiting < 1"
                                    @change="onChangeSelectMitra(list)"
                                />
                            </td>
                            <td class="uk-text-left">
                                {{ checkVerify(list.overtime_waiting) }}
                            </td>
                            <td class="uk-text-left">
                                <a
                                    style="text-decoration: none"
                                    href="javascript:void(0)"
                                    @click="detailOvertimeMitra(list._id.partner_id, list._id.request_company_office_id, list._id.request_role_id)"
                                >
                                    {{ list.worker_name || "-" }}
                                </a>
                            </td>
                            <td class="uk-text-left">{{ list.office_name }}</td>
                            <td class="uk-text-left">{{ list.role_name }}</td>
                            <td class="uk-text-center">
                                {{ list.total_overtime_hour }}
                            </td>
                            <td class="uk-text-center">
                                {{ list.total_overtime_accepted }}
                            </td>
                            <td class="uk-text-center">
                                {{ list.total_overtime_assigned }}
                            </td>
                            <td class="uk-text-center">
                                {{ list.avg_overtime_hrs }}
                            </td>
                        </tr>
                    </tbody>
                    <EmptyTable
                        v-else
                        :colspan="8"
                        :empty-text="'No entries found'"
                    />
                </table>
            </div>
            <pagination
                :total-data="lists.totalDocs"
                :change-limit="changeLimit"
                :change-page="changePage"
            />
        </div>

        <div id="date-filter" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog">
                <button
                    class="uk-modal-close-default"
                    type="button"
                    uk-close
                    @click="hideModal"
                ></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-center">
                        Choose date range
                    </h2>
                </div>
                <div class="uk-modal-body">
                    <div class="uk-margin">
                        <label class="uk-form-label" for="form-stacked-text"
                            >From</label
                        >
                        <div class="uk-form-controls">
                            <datetime
                                name="Start Date"
                                placeholder="dd/mm/yyy"
                                input-class="uk-input"
                                type="date"
                                v-model="meta.start_date"
                                :max-datetime="datetime.now().toISODate()"
                                value-zone="local"
                            ></datetime>
                        </div>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label" for="form-stacked-text"
                            >To</label
                        >
                        <div class="uk-form-controls">
                            <datetime
                                name="End Date"
                                placeholder="dd/mm/yyy"
                                input-class="uk-input"
                                type="date"
                                v-model="meta.end_date"
                                :min-datetime="
                                    datetime
                                        .fromISO(meta.start_date)
                                        .plus({ days: 1 })
                                        .toISODate()
                                "
                                :max-datetime="datetime.now().toISODate()"
                                value-zone="local"
                            ></datetime>
                        </div>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button
                        class="uk-button uk-button-primary uk-margin-small-left"
                        type="button"
                        @click="applyFilter"
                    >
                        Apply
                    </button>
                </div>
            </div>
        </div>

        <ConfirmationModal
            v-if="isShowConfirmationModal"
            :is-verify="isVerify"
            :start-date="dateCompleteStartDate"
            :end-date="dateCompleteEndDate"
            :selected-mitra="selectedMitra"
            @hideModal="isShowConfirmationModal = false"
            @confirm="verifyOrReject"
        />
    </div>
</template>

<script>
import formatter from "@/utils/formatter";
import { DateTime } from "luxon";
import { Datetime } from "vue-datetime";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import {
    notificationSuccess,
    notificationDanger,
    notificationDangerCustom
} from "@/utils/notification";
moment.locale("id");

export default {
    name: "OvertimeReportDetail",
    components: {
        Datetime,
        EmptyTable: () => import("@/components/globals/tables/EmptyTable"),
        LoadingTable: () => import("@/components/globals/tables/Loading"),
        Pagination: () => import("@/components/globals/Pagination"),
        ConfirmationModal: () => import("./Confirmation")
    },
    data() {
        return {
            datetime: DateTime,
            isLoading: false,
            meta: {
                start_date: null,
                end_date: null,
                worker_name: null,
                role_id: null,
                company_office_id: null,
                sorting: null,
                limit: 10,
                page: 1,
                status: null,
                method: null
            },
            officeOptions: [],
            roleOptions: [],
            selectedMitra: [],
            selectMultiple: false,
            isVerify: false,
            isShowConfirmationModal: false
        };
    },
    beforeMount() {
        if (window.UIkit.modal("#date-filter"))
            window.UIkit.modal("#date-filter").$destroy(true);
    },
    async mounted() {
        try {
            await Promise.all([
                this.setOfficeRoleOptions(),
                this.mapQuery()
            ]);
            if (Object.keys(this.$route.query).length !== 0) {
                await this.getData();
            }
        } catch (error) {
            notificationDanger(error);
        }
    },
    computed: {
        ...mapGetters({
            company_offices: "company_office/company_offices",
            roles: "option_data/roles",
            lists: "overtime_report/lists"
        }),
        dateCompleteStartDate() {
            return this.formatDate(this.meta.start_date);
        },
        dateCompleteEndDate() {
            return this.formatDate(this.meta.end_date);
        },
        isShowMultipleSelect() {
            return Boolean(
                this.meta && this.meta.status && this.meta.status == 0
            );
        },
    },
    watch: {
        async meta() {
            try {
                await this.getData();
            } catch (error) {
                notificationDanger(error);
            }
        }
    },
    methods: {
        ...mapActions({
            getOfficeRoleOptions: "overtime_report/getOfficeRoleOptions",
            getOvertimeReportList: "overtime_report/getOvertimeReportList",
            verifyReject: "overtime_report/verifyReject"
        }),
        async getData() {
            const payload = {
                ...this.meta,
                verify_status: this.meta.status
            };
            delete payload.status;
            await this.getOvertimeReportList(payload);
        },
        async setOfficeRoleOptions() {
            try {
                const result = await this.getOfficeRoleOptions();
                this.officeOptions = result.offices;
                this.roleOptions = result.roles;

                if (this.roleOptions.length > 0) {
                    this.roleOptions.sort((a, b) =>
                        a.name.toUpperCase() > b.name.toUpperCase()
                            ? 1
                            : b.name.toUpperCase() > a.name.toUpperCase()
                                ? -1
                                : 0
                    );
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async showModal() {
            await window.UIkit.modal("#date-filter").show();
        },
        async hideModal() {
            await window.UIkit.modal("#date-filter").hide();
        },
        async mapQuery() {
            if (this.$route.query.start_date) {
                this.meta.start_date = this.$route.query.start_date;
            }
            if (this.$route.query.end_date) {
                this.meta.end_date = this.$route.query.end_date;
            }
            if (this.$route.query.method) {
                this.meta.method = this.$route.query.method;
            }
            await this.changeQuery();
        },
        formatDate(data) {
            if (data == null) {
                return "-";
            }
            return formatter.dateComplete(data);
        },
        goBack() {
            this.$router.push({
                path: `/admin/workers/overtime-report`,
                query: this.$route.query
            });
        },
        async applyFilter() {
            if (!this.meta.start_date) {
                return notificationDangerCustom("Start date must be filled");
            }

            if (this.meta.start_date && !this.meta.end_date) {
                const start_date = moment(this.meta.start_date)
                    .add(this.meta.hour_diff, "hours")
                    .format();
                const end_date = moment(this.meta.start_date)
                    .add(30, "days")
                    .format();

                this.meta.start_date = start_date;
                this.meta.end_date = end_date;
            } else if (this.meta.start_date && this.meta.end_date) {
                const start_date = moment(this.meta.start_date)
                    .add(this.meta.hour_diff, "hours")
                    .format();
                const end_date = moment(this.meta.end_date)
                    .add(this.meta.hour_diff, "hours")
                    .format();

                if (start_date > end_date) {
                    return notificationDangerCustom(
                        "End date must be greater than start date"
                    );
                }

                this.meta.start_date = start_date;
                this.meta.end_date = end_date;
            }

            this.meta.start_date = moment(this.meta.start_date).format(
                "YYYY-MM-DD"
            );
            this.meta.end_date = moment(this.meta.end_date).format(
                "YYYY-MM-DD"
            );

            await this.changeQuery();
            await this.getData();
            await this.hideModal("date-filter");
        },
        filterDateText() {
            let date_filter_text = this.dateFilterAsText();
            if (date_filter_text != "") {
                return date_filter_text;
            }
            return "Choose date range";
        },
        dateFilterAsText() {
            let date_format = "";
            if (this.meta.start_date) {
                date_format =
                    date_format + this.formatDate(this.meta.start_date);
            }
            if (this.meta.end_date) {
                if (date_format !== "") {
                    date_format = date_format + " -";
                }
                date_format = date_format + this.formatDate(this.meta.end_date);
            }
            return date_format;
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        changeMeta(e, prop) {
            if (prop == "worker_name") {
                e.target.value = formatter.sanitizeString(e.target.value);
            }
            this.meta = {
                ...this.meta,
                [prop]: e.target.value || null
            };
        },
        async changeQuery() {
            const queryString = Object.keys(this.meta)
                .map((key) => key + "=" + this.meta[key])
                .join("&");
            await this.$router.replace(this.$route.path + "?reset");
            await this.$router.replace(this.$route.path + `?${queryString}`);
        },
        checkVerify(data) {
            const w = data > 0 ? `${data} Left` : `All Clear`;
            return w;
        },
        findSelectedMitra({ _id = null }) {
            return !!this.selectedMitra.find(
                (item) => (
                    item?._id?.partner_id?.toString() === _id?.partner_id?.toString() &&
                    item?._id?.request_company_office_id?.toString() === _id?.request_company_office_id?.toString() &&
                    item?._id?.request_role_id?.toString() === _id?.request_role_id?.toString()
                )
            );
        },
        onChangeSelectMitra(data = null) {
            let selected = [];
            const resultFindSameData = this.selectedMitra.find(
                (item) => (
                    item?._id?.partner_id?.toString() === data?._id?.partner_id?.toString() &&
                    item?._id?.request_company_office_id?.toString() === data?._id?.request_company_office_id?.toString() &&
                    item?._id?.request_role_id?.toString() === data?._id?.request_role_id?.toString()
                )
            );
            
            selected =
                this.selectedMitra.length > 0
                    ? resultFindSameData
                        ? this.selectedMitra.filter(
                            (item) => (
                                item?._id?.partner_id?.toString() !== data?._id?.partner_id?.toString() &&
                                item?._id?.request_company_office_id?.toString() !== data?._id?.request_company_office_id?.toString() &&
                                item?._id?.request_role_id?.toString() !== data?._id?.request_role_id?.toString()
                            )
                        )
                        : [...this.selectedMitra, ...[data]]
                    : [data];
            this.selectedMitra = selected;
            this.selectMultiple = selected.length > 0;
        },
        selectAllMitra() {
            this.selectMultiple = !this.selectMultiple;
            if (
                Array.isArray(this.lists.docs) &&
                this.lists.docs.length > 0 &&
                this.selectMultiple
            ) {
                for (const list of this.lists.docs) {
                    const findSameData = this.selectedMitra.find(
                        (item) => (
                            item?._id?.partner_id?.toString() === list?._id?.partner_id?.toString() &&
                            item?._id?.request_company_office_id?.toString() === list?._id?.request_company_office_id?.toString() &&
                            item?._id?.request_role_id?.toString() === list?._id?.request_role_id?.toString()
                        )
                    );
                    
                    if (!findSameData && list.overtime_waiting > 0) {
                        this.selectedMitra.push(list);
                    }
                }
            } else {
                this.selectedMitra = [];
            }
        },
        unselectAllMitra() {
            this.selectedMitra = [];
            this.selectMultiple = false;
        },
        showConfirmation({verify=false}){
            this.isVerify = verify;
            this.isShowConfirmationModal = true;
        },
        getTotalSelectedOvertime(){
            return this.selectedMitra.reduce((accum, item) => accum + item, 0);
        },
        async verifyOrReject({ data = null }){
            this.isLoading = true; 
            const payload = {
                overtime_ids: data,
                verify_status: this.isVerify ? 1 : 2
            };
            this.unselectAllMitra();
            const msg = this.isVerify ? 'approved': 'rejected';
            try {                
                const response = await this.verifyReject(payload);
                if (response && response.status === 'OK') {
                    notificationSuccess(`Overtime request ${msg}!`);
                    this.getData();
                    this.unselectAllMitra();
                } else {
                    notificationDangerCustom(`Failed to ${msg} overtime request!`);
                }
            } catch (error) {
                notificationDanger(error);
            }
            this.isShowConfirmationModal = false;
            this.isLoading = false;
        },
        detailOvertimeMitra(id, request_company_office_id, request_role_id){
            this.meta.request_company_office_id = request_company_office_id;
            this.meta.request_role_id = request_role_id;
            
            this.$router.push({ path: `${id}`, query: {...this.meta, page: 1} });
        }
    }
};
</script>
<style scoped>
thead {
    background-color: #0abab5;
}
th {
    color: #ffffff;
}
td {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #000000;
}
.bg-yellow {
    background-color: #ffe500;
}
input[type="checkbox"] {
    border-color: #979797;
}
input[type="checkbox"]:not(:checked) {
    background-color: white;
}
input[type="checkbox"]:disabled {
    background-color: #cccccc;
}
.action-button:disabled {
    color: white;
    background-color: #979797 !important;
}
</style>
